import { ISO } from '@arora/common'

import { useI18n } from 'vue-i18n'

type keyboardCheckComposable = {
  convertLayout: (str: string) => string
  keyboardSearchCheck: (check: string, str: string | null | undefined) => boolean
}

export default function useKeyboardCheck(): keyboardCheckComposable {
  const { locale } = useI18n()

  const En = "qwertyuiop[]asdfghjkl;'zxcvbnm,.",
    Ru = 'йцукенгшщзхъфывапролджэячсмитьбю',
    Ua = 'йцукенгшщзхїфівапролджєячсмитьбю'

  // Reusable function to create a mapping between two strings
  function createMapping(source: string, target: string): Record<string, string> {
    const mapping: Record<string, string> = {}

    // Convert the source string into an array of characters
    const sourceChars = [...source]
    const targetChars = [...target]

    for (const [index, sourceChar] of sourceChars.entries()) {
      const targetChar = targetChars[index]

      // Map both lowercase and uppercase versions
      mapping[sourceChar] = targetChar
      mapping[sourceChar.toLowerCase()] = targetChar.toLowerCase()
    }

    return mapping
  }

  const EnRu: Record<string, string> = createMapping(En, Ru)
  const EnUa: Record<string, string> = createMapping(En, Ua)

  function convertLayout(str: string): string {
    let result = ''
    for (let index = 0; index < str.length; index++) {
      const symbol = str.toLowerCase()[index]
      if (symbol === ' ') {
        result += symbol
      } else {
        let symbolConverted: string | null = null
        switch (locale.value.toUpperCase()) {
          case ISO.RussianFederation: {
            symbolConverted = EnRu[symbol]
            break
          }
          case ISO.Ukraine: {
            symbolConverted = EnUa[symbol]
            break
          }
        }

        if (symbolConverted) result += symbolConverted
      }
    }

    return result
  }

  function keyboardSearchCheck(
    check: string,
    str: string | null | undefined
  ): boolean {
    if (!str) return true

    const converted = convertLayout(str)

    if (converted.trim().length > 0) {
      return (
        check.toLowerCase().includes(str.toLowerCase()) ||
        check.toLowerCase().includes(converted.toLowerCase())
      )
    }

    return check.toLowerCase().includes(str.toLowerCase())
  }

  return {
    convertLayout,
    keyboardSearchCheck
  }
}
